enum EventTypeEnum {
    // Drawings
    drawingStatusesChange = 1, // Смена статусов чертежей
    drawingDataRolesAssignment, // Присвоение ролей в данных по чертежу - 2
    drawingAnnotationsCreation, // Создание замечаний - 3
    drawingAnnotationsChange, // Изменение замечаний  - 4
    drawingAnnotationsByGroupMove, // Перемещение замечаний по группам - 5
    drawingAnnotationsFulfillment, // Выполнение замечаний - 6
    drawingAnnotationsNewMessage, // Новые сообщения в замечаниях - 7

    // Tasks
    tasksAssignment, // Назначение задач - 8
    tasksDataChange, // Редактирование данных и описания задач - 9
    tasksStatusChange, // Изменение статусов задач - 10
    tasksNewMessages, // Новые сообщения в задачах - 11
    tasksFulfillment, // Выполнение задач исполнителями - 12
    tasksDeadlineChange, // Продление сроков задач - 13

    tasksCreate, // Создание задачи - 14
    drawingAcceptReturnRequest, // Принятие запроса на возврат чертежа в работу - 15
    drawingRejectReturnRequest, // Отклонение запроса на возврат чертежа в работу  - 16
    drawingCreateReturnRequest, // Создание запроса на возврат чертежа в работу  - 17

    logs, // logs (empty) - 18

    drawingExpirationDate, // Истекших сроки проверки чертежа/устранения замечаний - 19
}

export default EventTypeEnum;
